import { routers, routing } from 'outlinejs';

import { CartController } from './controllers';

export default class extends routers.BaseRouter {
  static get urlPatterns() {
    return {
      '': routing.url('cart:main', CartController),
      'cart/:projectId:': routing.url('cart:main', CartController)
    };
  }
}
