import { components } from 'outlinejs';
import React from 'react';
import ReactTooltip from 'react-tooltip';
import PropTypes from 'prop-types';

const Loader = () => <div className="promotions-banner-loader" />;

const PromotionBanner = ({
  promotionLabel,
  promotionButtonLabel,
  promotionDescription,
  couponCode,
  activeCoupons,
  onPromotionBannerAdd
}) => {
  const onClick = () => onPromotionBannerAdd(couponCode);

  let disabled = '';
  if (
    activeCoupons &&
    activeCoupons
      .map((activeCoupon) => activeCoupon.toUpperCase())
      .includes(couponCode.toUpperCase())
  ) {
    disabled = 'disabled';
  }
  const promotionBannerElementClass = `col-xs-12 no-bs-padding promotions-banner-element ${disabled}`;

  return (
    <div className="row">
      <div className={promotionBannerElementClass}>
        <div className="row no-bs-margin">
          <div
            className="col-xs-8 no-bs-padding promotions-banner-code"
            data-tip={promotionDescription}>
            <span>
              {promotionLabel}
              <br />
              &quot;{couponCode}&quot;
            </span>
          </div>
          <div className="col-xs-4 no-bs-padding promotions-banner-button">
            <a onClick={onClick}>{promotionButtonLabel}</a>
          </div>
        </div>
        <ReactTooltip className="ae-tooltip" />
      </div>
    </div>
  );
};

export class PromotionsBanner extends components.BaseComponent {
  render() {
    const { coupons, activeCoupons, onPromotionBannerAdd } = this.props;
    const isLoading = coupons === undefined;

    return (
      <div className="row no-bs-margin">
        <div className="col-xs-12">
          {isLoading && <Loader />}
          {!isLoading &&
            coupons.map((coupon) => (
              <PromotionBanner
                key={coupon.code}
                couponCode={coupon.code}
                activeCoupons={activeCoupons}
                onPromotionBannerAdd={onPromotionBannerAdd}
                promotionLabel={this.i18n.gettext('Promotion')}
                promotionButtonLabel={this.i18n.gettext('Applica')}
                promotionDescription={coupon.description}
              />
            ))}
        </div>
      </div>
    );
  }
}

PromotionBanner.propTypes = {
  promotionLabel: PropTypes.string,
  promotionButtonLabel: PropTypes.string,
  promotionDescription: PropTypes.string,
  couponCode: PropTypes.string,
  activeCoupons: PropTypes.array,
  onPromotionBannerAdd: PropTypes.func
};
