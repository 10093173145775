import { components } from 'outlinejs';
import React from 'react';
import classNames from 'classnames';
import axios from 'axios';
import LazyLoad from 'react-lazyload';

import Logger from '../../core/logger';

export class ProductPreview extends components.BaseComponent {
  constructor(props) {
    super(props);
    this.showPreview = this.showPreview.bind(this);
    this.state = {
      previewSvg: null,
      svgUrl: null
    };
  }

  componentDidMount() {
    const { previewImage, previewSvgContent } = this.props.preorder;
    const previewSvgUrl =
      previewImage && previewImage.fileType === 'SinglePageSvg' ? previewImage.fileUrl : null;

    if (previewSvgUrl) {
      this.setState({ svgUrl: previewSvgUrl });
      this.createPreviewSvg(previewSvgUrl);
    }
    if (previewSvgContent) {
      // patch Freehand 591 font
      const previewSvg = previewSvgContent.replace('Freehand 591', "'Freehand 591'");
      this.setState({ previewSvg });
    }
  }

  showPreview() {
    const { preorder } = this.props;
    const { previewSvg } = this.state;

    if (previewSvg) {
      this.props.delegate.modalSvgPreview(previewSvg, true);
    } else {
      this.props.delegate.modalImagePreview(preorder.fullImageUrl(), true);
    }
  }

  async getPreviewSvg(svgUrl) {
    const request = axios;
    await request
      .get(svgUrl)
      .then((response) => {
        const { data } = response;
        const doc = new DOMParser().parseFromString(data, 'text/html');
        const svg = doc.getElementsByTagName('svg')[0];
        const s = new XMLSerializer();
        const svgStr = s.serializeToString(svg);
        this.setState({
          previewSvg: svgStr
        });
        this.render(this.context);
      })
      .catch((error) => {
        Logger.error('setPreviewSvg: Error on SVG file retrieve', {
          url: svgUrl,
          error
        });
      });
  }

  async createPreviewSvg(previewSvgUrl) {
    if (!this.state.svgUrl) {
      await this.getPreviewSvg(previewSvgUrl);
    }
  }

  render() {
    const { preorder } = this.props;
    const { svgUrl, previewSvg } = this.state;

    let loadingImageClass = 'img-responsive';

    let previewImageUrl = preorder.previewImageUrl(5);
    if (!svgUrl && !previewSvg) {
      if (previewImageUrl !== undefined && previewImageUrl.indexOf('loading-image.gif') !== -1) {
        loadingImageClass += ' loading-image';
      }
      if (previewImageUrl !== undefined && previewImageUrl.indexOf('error-image') !== -1) {
        loadingImageClass += ' error-image';
      }
      if (!previewImageUrl) {
        previewImageUrl = preorder.previewImageUrl(2);
      }
    }

    const previewClass = classNames('cart-project-item-img', {
      'disabled-zoom':
        loadingImageClass.indexOf('loading-image') !== -1 ||
        loadingImageClass.indexOf('error-image') !== -1
    });

    return (
      <LazyLoad height={188} once>
        <a className={previewClass} onClick={this.showPreview}>
          {previewSvg ? (
            <div className="svg-container" dangerouslySetInnerHTML={{ __html: previewSvg }} />
          ) : (
            <img alt={'loading'} className={loadingImageClass} src={previewImageUrl} />
          )}
        </a>
      </LazyLoad>
    );
  }
}
