import { components } from 'outlinejs';
import classNames from 'classnames';
import React from 'react';
import { ProjectComponent } from './projectComponent';

const ProjectItemValidationLoader = () => <div className="loading-validation" />;

class ProjectItemOpenButton extends components.BaseComponent {
  render() {
    const { isOpen, onToggle } = this.props;
    return (
      <a className="btn-project-item-action" onClick={onToggle}>
        {isOpen ? <i className="fa fa-search-minus" /> : <i className="fa fa-search-plus" />}
        {isOpen ? (
          <span className="mobile-hide">{this.i18n.gettext('contrai finestra')}</span>
        ) : (
          <span className="mobile-hide">{this.i18n.gettext('espandi finestra')}</span>
        )}
      </a>
    );
  }
}

class ProjectItemValidationButton extends components.BaseComponent {
  render() {
    const { canBeOrdered, onCanBeOrderedProjectClick, onCanNotBeOrderedProjectClick } = this.props;

    if (canBeOrdered) {
      return (
        <a
          className="btn-project-item-action project-item-add"
          onClick={onCanBeOrderedProjectClick}>
          <i className="fa fa-share-square-o fa-rotate-270" />
          <span className="green mobile-hide">{this.i18n.gettext("aggiungi all'ordine")}</span>
        </a>
      );
    }
    return (
      <a className="btn-project-item-action" onClick={onCanNotBeOrderedProjectClick}>
        <i className="fa fa-exclamation-triangle red" />
        <span className="mobile-hide">{this.i18n.gettext("controlla l'ordine")}</span>
      </a>
    );
  }
}

export class ProjectItem extends components.BaseComponent {
  constructor(props) {
    super(props);
    this.toggleDetail = this.toggleDetail.bind(this);
    this.addSelectedProjectToCart = this.addSelectedProjectToCart.bind(this);
    this.projectItemElement = undefined;
    this.state = {
      openDetail: false
    };
  }

  get productCategory() {
    const { productCategory } = this.props.project;
    if (productCategory === 'HomeDecor') {
      return 'Wall&Table Decor';
    }
    if (productCategory === 'UsbDrivePackaging' || productCategory === 'UsbDrive') {
      return 'Usb&Box';
    }
    return productCategory;
  }

  toggleDetail() {
    if (!this.props.project.loaded) {
      this.props.delegate.getBucketProjectPreOrders(this.props.project);
    }
    this.setState((prevState) => ({
      openDetail: !prevState.openDetail
    }));
  }

  addSelectedProjectToCart() {
    this.props.delegate.addProjectToCart(this.props.project);
  }

  componentDidMount() {
    const selectedProjectId = this.props.delegate.selectedProject;
    const { id } = this.props.project;
    if (id === selectedProjectId) {
      this.projectItemElement.scrollIntoView({ behavior: 'smooth' });
      this.toggleDetail();
    }
  }

  render() {
    const {
      name,
      formattedTotalPrice,
      isValidationLoading,
      canBeOrdered,
      isPreorderCollectionLoading
    } = this.props.project;
    const { openDetail } = this.state;
    const itemClass = classNames(
      'col-xs-12',
      'bucket-products-tr',
      { open: openDetail },
      {
        loading:
          isPreorderCollectionLoading ||
          this.props.delegate.cartProjectComponentIsLoading(this.props.project.id)
      }
    );
    const { productCategory } = this;

    return (
      <div
        className="row project-item"
        ref={(elem) => {
          this.projectItemElement = elem;
        }}>
        <div className="col-xs-12">
          <div className="row">
            <div className={itemClass}>
              <div className="row">
                <div className="col-xs-2 col-sm-2 bucket-products-td">
                  <ProjectItemOpenButton isOpen={openDetail} onToggle={this.toggleDetail} />
                </div>
                <div className="col-sm-3 bucket-products-td mobile-hide">{productCategory}</div>
                <div className="col-xs-8 col-sm-3 bucket-products-td project-name">{name}</div>
                <div className="col-sm-2 bucket-products-td mobile-hide">
                  &nbsp;
                  {formattedTotalPrice}
                </div>
                <div className="col-xs-2 col-sm-2 bucket-products-td ">
                  {isValidationLoading ? (
                    <ProjectItemValidationLoader />
                  ) : (
                    <ProjectItemValidationButton
                      canBeOrdered={canBeOrdered}
                      onCanBeOrderedProjectClick={this.addSelectedProjectToCart}
                      onCanNotBeOrderedProjectClick={this.toggleDetail}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
          {openDetail && (
            <ProjectComponent project={this.props.project} delegate={this.props.delegate} />
          )}
        </div>
      </div>
    );
  }
}
