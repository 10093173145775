import { runtime } from '@outlinejs/contexts';
import { conf, routing } from 'outlinejs';

import { sprintf } from '../core/sprintf';
import { promiseRetry } from '../core/utils';
import { GuiNotification } from '../core/notifications';
import { CartCheckoutBaseController } from '../core/controllers';
import Logger from '../core/logger';
import { BucketProjectCollection, CartProjectCollection } from '../projects/managers';
import { Project } from '../projects/models';
import { ProfessionalPreOrder } from '../pre-orders/models';
import { ProfessionalPreOrdersCollection } from '../pre-orders/managers';

import { AEGuideConfiguration } from './models';
import { CartContentView } from './views';
import { User } from '../auth/models';
import EventTracker, { productCategoryInterface } from '../core/eventTracker';
import { VouchersCollection } from '../vouchers/managers';
import { CustomError } from '../core/errors';

class AeGuideController {
  async getProject(projectId) {
    return await new Project({ id: projectId }).fetch();
  }

  async getConfiguration(configurationId) {
    return await new AEGuideConfiguration({ id: configurationId }).fetch();
  }

  async addAEGuide(shopCode, guideType, cartGuid) {
    const customerUserShopCode = shopCode;
    const aeGuideProjects = await this.fetchAEGuidesWithRetry(customerUserShopCode, cartGuid);
    let aeGuideProject;
    if (aeGuideProjects.length === 0) {
      aeGuideProject = await this.createAEGuide(customerUserShopCode, guideType);
      return aeGuideProject;
    }
    let projectId = null;
    let aeGuidePreorderCollection = [];
    const aeGuideConfigurations = [];
    aeGuidePreorderCollection = aeGuideProjects.map(async (project) => {
      try {
        const aeGuidePreorder = await this.fetchPreOrder(project.id);
        aeGuideConfigurations.push({
          configurationId: aeGuidePreorder.models[0].configurationId,
          projectId: aeGuidePreorder.models[0].projectId
        });
      } catch (err) {
        const timestamp = new Date().getTime();
        Logger.error('AeGuideController addAEGuide.getPreorderCollection', {
          error: err,
          errorCode: timestamp,
          project: project.id
        });
      }
    });
    await Promise.all(aeGuidePreorderCollection);

    const aeGuideConfigurationsPromisses = aeGuideConfigurations.map(async (configuration) => {
      try {
        const aeGuideConfiguration = await this.getConfiguration(configuration.configurationId);
        if (aeGuideConfiguration.aeGuideType === guideType) {
          projectId = configuration.projectId;
        }
      } catch (err) {
        const timestamp = new Date().getTime();
        Logger.error('AeGuideController addAEGuide.getAeGuideConfigurations', {
          error: err,
          errorCode: timestamp
        });
      }
    });

    await Promise.all(aeGuideConfigurationsPromisses);

    if (!projectId) {
      aeGuideProject = await this.createAEGuide(customerUserShopCode, guideType);
      return aeGuideProject;
    }
    await this.setAeGuideQuantity(projectId);

    return aeGuideProject;
  }

  async createAEGuide(shopCode, guideType) {
    const configuration = await this.createAEGuideConfiguration(shopCode, guideType);
    const preorder = configuration ? await this.createAEGuidePreorder(configuration) : null;
    let aeGuideProject;
    try {
      aeGuideProject = await this.getProject(preorder.projectId);
      await aeGuideProject.fetchPreorderCollection();
      await this.addProjectToCart(aeGuideProject);
    } catch (err) {
      const timestamp = new Date().getTime();
      Logger.error('AeGuideController createAEGuide.getProject', {
        error: err,
        errorCode: timestamp,
        guideType,
        preorder
      });
    }
    return aeGuideProject;
  }

  async createAEGuideConfiguration(shopCode, typeCode) {
    let aeGuideConfiguration = new AEGuideConfiguration();
    try {
      aeGuideConfiguration.shopCode = shopCode;
      aeGuideConfiguration.aeGuideType = typeCode;
      aeGuideConfiguration = await aeGuideConfiguration.save();
    } catch (error) {
      Logger.error('AeGuideController createAEGuide', {
        error,
        context: this.context
      });
      return false;
    }
    return aeGuideConfiguration;
  }

  /** Create AEGuide Pre-Order * */

  async createAEGuidePreorder(configuration) {
    const preOrderData = {
      configurationUrl: null,
      configurationId: configuration.id,
      productType: configuration.productType,
      shopCode: configuration.shopCode,
      projectId: null
    };
    let preOrder = new ProfessionalPreOrder();
    try {
      preOrder = await preOrder.save(preOrderData);
    } catch (err) {
      const timestamp = new Date().getTime();
      Logger.error('AeGuideController createAEGuidePreorder.createPreOrder', {
        error: err,
        errorCode: timestamp,
        preOrderData
      });
    }

    return preOrder;
  }

  async fetchAEGuides(shopcode, cartGuid) {
    return await new CartProjectCollection().fetchAEGuides(shopcode, cartGuid);
  }

  async fetchAEGuidesWithRetry(shopCode, cartGuid) {
    return await promiseRetry(
      this.fetchAEGuides.bind(this, shopCode, cartGuid),
      'Retry to fetch AE Guides',
      { shopCode, cartGuid }
    );
  }

  async fetchPreOrder(projectId) {
    return await new ProfessionalPreOrdersCollection().filterByProjectId(projectId);
  }

  async setAeGuideQuantity(projectId) {
    let aeGuidePreorderCollection = null;
    let aeGuidePreorder = null;
    let quantity = null;
    try {
      aeGuidePreorderCollection = await this.fetchPreOrder(projectId);
      aeGuidePreorder = aeGuidePreorderCollection.models[0];
      quantity = aeGuidePreorder.quantity + 1;
    } catch (err) {
      const timestamp = new Date().getTime();
      Logger.error('AeGuideController setAeGuideQuantity', {
        error: err,
        errorCode: timestamp,
        preorder: aeGuidePreorder,
        quantity
      });
    }
    await this.changePreorderQuantity(aeGuidePreorder, quantity);
  }

  async changePreorderQuantity(preorder, value) {
    const oldquantity = preorder.quantity;
    try {
      preorder.quantity = value;
      await preorder.save();
    } catch (err) {
      preorder.quantity = oldquantity;
      const timestamp = new Date().getTime();
      Logger.error('AeGuideController changePreorderQuantity', {
        error: err,
        errorCode: timestamp,
        preorder,
        quantity: value
      });
    }
  }

  async addProjectToCart(project) {
    try {
      project.state = conf.settings.PROJECT_STATE_CART;
      await project.save();
    } catch (err) {
      Logger.error('AeGuideController addProjectToCart', {
        error: err,
        project
      });
    }
  }
}

export class CartController extends CartCheckoutBaseController {
  get view() {
    return CartContentView;
  }

  get context() {
    return Object.assign(super.context, {
      bucketProjects: this.bucketProjects, // collection dei projects
      selectedProject: this.selectedProject,
      fetchedBucketProjectIds: this.fetchedBucketProjectIds,
      contentIsLoading: this.contentIsLoading,
      aeGuideProject: this.aeGuideProject,
      previewSvg: this.previewSvg,
      promotions: this.promotions
    });
  }

  async initContentProps(projectId) {
    await super.initContentProps();

    this.selectedProject = undefined;
    if (projectId) {
      this.selectedProject = parseInt(projectId);
    }
    this.pageSize = conf.settings.PAGE_SIZE;
    this.page = 1;
    this.contentIsLoading = false;
    this.previewSvg = null;

    this.bucketProjects = {
      collection: new BucketProjectCollection(),
      isLoading: false,
      bottomreached: false
    };

    this.fetchedBucketProjectIds = [];

    this.promotions = undefined;
  }

  async init(projectId) {
    this.startInitialRendering();

    await this.initContentProps(projectId);

    this.bucketProjects.firstLoad = true;

    if (runtime.isClient) {
      if (this.user && this.customerUser) {
        if (!this.request.customerUserIsAuthorized) {
          this.response.navigate('unauthorized');
          return;
        }

        EventTracker.log(this.customerUser, 'cart_view');

        this.stopInitialRendering();

        this.isBlockingMessageActive();

        this.onEnterMessageModalIsActive();

        this.startLoadingPage();

        // 1. load cart header must be the first API callback to ensure data consistency
        this.cartHeader.isLoading = true;
        this.render(this.context);

        await this.loadCartHeader(false, false);

        if (
          this.hasCartOnlinePayment(this.cartHeader.model) &&
          this.hasPendingPayment(this.cartHeader.model)
        ) {
          this.showOrderInWaitingPaymentAlert();
        }

        // 2. load cart rows to syncronize projects validation
        await this.getCartProjects();

        // load promotions async
        this.getPromotions();

        // 3. load again CartHeader to syncronize invalid projects
        await Promise.all([this.getBucketProjects(), this.loadCartHeader()]);

        this.bucketProjects.firstLoad = false;
        this.stopLoadingPage();
      }
    }
  }

  async addAEGuide(guideType) {
    this.startLoadingPage();

    const aeGuideController = new AeGuideController();
    const aeGuideProject = await aeGuideController.addAEGuide(
      this.customerUser.shopCode,
      guideType,
      this.cartHeader.model.id
    );

    this.render(this.context);
    await this.getCartProjects();

    await this.getProjectsQuantity();

    await Promise.all([this.loadCartHeader(), this.getBucketProjects()]);
    this.fixPosition();
    this.bucketProjects.firstLoad = false;

    if (aeGuideProject && aeGuideProject.preorderCollection.length === 0) {
      EventTracker.log(this.customerUser, 'cart_add_success', {
        product_category: productCategoryInterface(aeGuideProject.productCategory),
        project_id: aeGuideProject.id
      });
    }
    this.stopLoadingPage();
  }

  fixPosition() {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }

  async fetchPromotions(country) {
    try {
      const vouchers = await new VouchersCollection().fetchVouchers(
        this.customerUser.shopCode,
        country,
        this.request.language
      );
      return vouchers.models;
    } catch (error) {
      Logger.error('CartController.fetchPromotions', {
        error
      });
      return [];
    }
  }

  async getPromotions() {
    let country = this.customerUser.country;

    // todo remove on 05/03/2020
    if (!country) {
      const user = new User({ id: 'current' });
      await user.fetch();
      country = user.country;
    }

    this.promotions = await this.fetchPromotions(country);
    this.render(this.context);
  }

  async applyPromotion(coupon) {
    if (this.cartProjects.collection.length === 0) {
      GuiNotification.modalInfo(
        this.i18n.gettext('Inserisci un prodotto nel carrello prima di applicare un voucher')
      );
      return;
    }
    if (this.cartHeader.model.couponCodes.length === this.cartHeader.model.couponsLimit) {
      GuiNotification.modalInfo(
        this.i18n.gettext('Si prega di rimuovere uno dei voucher già applicati prima di procedere')
      );
      return;
    }
    this.addCoupon(coupon, 'promo_code_banner_apply');
  }

  async fetchBucketProjects(shopCode, pagesize, page) {
    return await this.bucketProjects.collection.fetchBucketProjects(shopCode, pagesize, page);
  }

  async fetchBucketProjectsWithRetry(
    shopCode = this.customerUser.shopCode,
    pagesize = this.pageSize,
    page = this.page
  ) {
    return await promiseRetry(
      this.fetchBucketProjects.bind(this, shopCode, pagesize, page),
      'Retry to fetch bucket projects',
      { shopCode, pagesize, page }
    );
  }

  fetchBucketProjectsValidation() {
    for (let i = 0; i < this.bucketProjects.collection.models.length; i++) {
      if (this.bucketProjects.collection.models[i].isValidationLoading === undefined) {
        this.bucketProjects.collection.models[i]
          .fetchValidation()
          .then(() => {
            this.render(this.context);
          })
          .catch((err) => {
            Logger.error('CartController.fetchBucketProjectsValidation', {
              error: err
            });
          });
      }
    }
  }

  async getBucketProjects(pagesize = this.pageSize, page = this.page) {
    this.bucketProjects.isLoading = true;
    this.render(this.context);

    try {
      await this.fetchBucketProjectsWithRetry(this.customerUser.shopCode, pagesize, page);
    } catch (error) {
      Logger.error('CartController.getBucketProjects', {
        error,
        pagesize,
        page
      });
    }

    this.bucketProjects.isLoading = false;
    this.render(this.context);

    this.fetchBucketProjectsValidation();
  }

  async loadMoreBucketProjects() {
    if (this.bucketProjects.bottomreached) {
      return;
    }

    const oldCollectionLength = this.bucketProjects.collection.length;
    this.page++;

    await this.getBucketProjects();

    if (this.bucketProjects.collection.length === oldCollectionLength) {
      this.bucketProjects.bottomreached = true;
      this.render(this.context);
    }
  }

  async changeQuantity(project, preorder, value) {
    await this.changePreorderQuantity(project, preorder, value);
  }

  async changePreorderQuantity(project, preorder, value) {
    if (project.state === conf.settings.PROJECT_STATE_CART) {
      this.cartHeader.isLoading = true;
    }
    this.changeQuantityInProgress = true;
    this.render(this.context);

    const oldquantity = preorder.quantity;
    try {
      preorder.quantity = value;
      this.addProjectToLoadingCollection(project.id);

      this.render(this.context);
      await preorder.save();
    } catch (err) {
      preorder.quantity = oldquantity;
      const timestamp = new Date().getTime();
      Logger.error('CartCheckoutBaseController.changePreorderQuantity', {
        error: err,
        errorCode: timestamp,
        project,
        preorder,
        quantity: value
      });
      GuiNotification.modalError(
        this.i18n.gettext('Aggiornamento della quantità è fallito '),
        `Error detail: CartController.changePreorderQuantity - update quantity: ${timestamp}`
      );
    } finally {
      if (project.state === conf.settings.PROJECT_STATE_CART) {
        await this.getCartProjects();
        await this.loadCartHeader();
      } else {
        await this.getBucketProject(project, true, preorder.descriptionVisible);
      }
      this.cartHeader.isLoading = false;
      this.removeProjectFromLoadingCollection(project.id);
      this.render(this.context);
    }
  }

  async getBucketProject(project, reloadPreorders = true, includePreOrderDescription = false) {
    try {
      await project.fetch();

      if (reloadPreorders) {
        project.preorderCollectionLoaded = false;
        await this.getBucketProjectPreOrders(project, includePreOrderDescription);
      }

      this.render(this.context);
    } catch (error) {
      Logger.error('CartController.getBucketProject', {
        error
      });
    }
  }

  async getBucketProjectPreOrders(project, includePreOrderDescription = false) {
    try {
      await this.fetchBucketProjectsPreorder([project], includePreOrderDescription);
      this.render(this.context);
    } catch (error) {
      Logger.error('CartController.getBucketProjectPreOrders', {
        error
      });
    }
  }

  async getCartProjects() {
    await super.getCartProjects();
  }

  async fetchBucketProjectsPreorder(newBucketProjectsList, includePreOrderDescription = false) {
    const promises = newBucketProjectsList.map(async (project) => {
      await this.fetchPreOrderCollectionWithRetry(project, includePreOrderDescription);
    });
    await Promise.all(promises);
  }

  /*
   * Do NOT call Cart and Buckets together. (Backend limitation)
   * */
  async syncCollections(pagesize = this.pageSize, page = this.page) {
    //eslint-disable-line
    await this.getCartProjects();
    await this.loadCartHeader();
    // we need to reload buckets to load next project
    await this.getBucketProjects(pagesize, page);
  }

  async getProductDescription(preorder) {
    if (!preorder.descriptionLoaded) {
      await preorder.fetchDescription();
      // render context to update loading value
      this.render(this.context);
    }
  }

  async toggleProductDescription(preorder) {
    preorder.descriptionVisible = !preorder.descriptionVisible;
    this.render(this.context);
  }

  async addProjectToCart(project) {
    try {
      this.startLoadingPage();
      this.cartProjects.isLoading = true;
      await this.bucketProjects.collection.remove(project);
      await this.cartProjects.collection.add(project, { at: 0 });
      this.render(this.context);

      project.state = conf.settings.PROJECT_STATE_CART;
      await project.save();

      await this.syncCollections();
      this.fixPosition();

      EventTracker.log(this.customerUser, 'cart_add_success', {
        product_category: productCategoryInterface(project.productCategory),
        project_id: project.id
      });
    } catch (err) {
      const error = new CustomError(err);

      Logger.error('CartController.addProjectToCart', {
        error: err,
        project
      });
      this.bucketProjects.collection.add(project);
      this.cartProjects.collection.remove(project);
      this.cartProjects.isLoading = false;
      GuiNotification.modalError(
        this.i18n.gettext('Non è stato possivile aggiungere il prodotto al carrello'),
        `Error detail: CartController.addProjectToCart - : ${err}`
      );

      EventTracker.log(this.customerUser, 'cart_add_error', {
        product_category: productCategoryInterface(project.productCategory),
        error_code: error.name
      });
    } finally {
      this.stopLoadingPage();
    }
  }

  async removeProjectFromCart(project) {
    try {
      this.startLoadingPage();
      this.cartProjects.isLoading = true;
      await this.cartProjects.collection.remove(project);
      await this.bucketProjects.collection.add(project, { at: 0 });
      this.render(this.context);

      project.state = conf.settings.PROJECT_STATE_BUCKET;
      await project.save();

      await this.syncCollections();

      EventTracker.log(this.customerUser, 'remove_from_cart_success', {
        product_category: productCategoryInterface(project.productCategory),
        project_id: project.id
      });
    } catch (err) {
      const error = new CustomError(err);

      Logger.error('CartController.removeProjectFromCart', {
        error: err,
        project
      });
      this.cartProjects.isLoading = false;
      this.render(this.context);

      EventTracker.log(this.customerUser, 'remove_from_cart_error', {
        product_category: productCategoryInterface(project.productCategory),
        error_code: error.name
      });
    } finally {
      this.stopLoadingPage();
    }
  }

  async goToProductList() {
    this.contentIsLoading = true;
    this.render(this.context);
    // let url = `${conf.settings.AE_CONFIGURATOR_BASE_URL}?language=${this.request.language}`;
    const url = conf.settings.ENV_PRODUCTION
      ? conf.settings.PRODUCTS_LIST_URLS[this.request.language]
      : `${conf.settings.AE_CONFIGURATOR_BASE_URL}?language=${this.request.language}`;
    this.response.navigate(url);
  }

  async deleteProject(project) {
    try {
      this.contentIsLoading = true;
      this.render(this.context);
      await project.destroy();
      try {
        if (project.state === conf.settings.PROJECT_STATE_CART) {
          await this.syncCollections(this.pageSize * this.page, 1);

          EventTracker.log(this.customerUser, 'remove_from_cart_success', {
            product_category: productCategoryInterface(project.productCategory),
            project_id: project.id
          });
        } else if (project.state === conf.settings.PROJECT_STATE_BUCKET) {
          await this.getBucketProjects(this.pageSize * this.page, 1);
        } else {
          throw Error(`Invalid project state: ${project.state}`);
        }
      } catch (err) {
        Logger.error(
          'CartController.deleteProject - unable to refresh cart, bucket and header after successfully delete',
          {
            error: err,
            project
          }
        );
        this.contentIsLoading = false;
        this.render(this.context);
      }
    } catch (err) {
      Logger.error('CartController.deleteProject', {
        error: err,
        project
      });
    } finally {
      this.contentIsLoading = false;
      await this.getProjectsQuantity();
      this.render(this.context);
    }
  }

  async modalDeleteProject(project, preorder) {
    let text = sprintf(
      this.i18n.gettext(
        'Stai per cancellare il progetto %s ed i relativi prodotti accessori, se presenti. Vuoi procedere?'
      ),
      project.name
    );
    if (
      project.preorderCollection.length === 1 &&
      preorder &&
      preorder.productType === conf.settings.PRODUCT_TYPE_AE_GUIDE
    ) {
      text = this.i18n.gettext('Do you want to remove AE Guide?');
    }
    GuiNotification.modalConfirm(
      text,
      this.deleteProject.bind(this, project),
      null,
      null,
      null,
      null
    );
    this.render(this.context);
  }

  async modalDuplicationProject(project) {
    GuiNotification.modalDuplication(this.redirectToChoseProjectPage.bind(this, project.id));
    this.render(this.context);
  }

  async modalSvgPreview(svgPreview, enableDownload) {
    GuiNotification.modalPreview(null, svgPreview, enableDownload);
    this.render(this.context);
  }

  async modalImagePreview(imageUrl, enableDownload) {
    GuiNotification.modalPreview(imageUrl, null, enableDownload);
    this.render(this.context);
  }

  goToCheckout() {
    // todo: controllare anche lo stato del carrello , cartState != New
    if (this.cartHeader.model.numberOfProducts > 0) {
      this.contentIsLoading = true;
      this.render(this.context);
      const checkoutUrl = routing.Utils.reverse('checkout:main');
      this.response.navigate(checkoutUrl);
    } else {
      GuiNotification.modalInfo(
        this.i18n.gettext("Aggiungi prodotti al carrello per poter procedere all'aquisto")
      );
    }
  }

  async isProjectCreationStatusValid(project, preorder) {
    if (project.isDuplicationInProgress()) {
      this.project = await project.fetch();
      if (this.project.isDuplicationInProgress()) {
        GuiNotification.modalInfo(
          this.i18n.gettext('Stiamo ultimando la copia dei file. Ti preghiamo di attendere.')
        );
        return false;
      }
      await preorder.fetch();
      this.render(this.context);
    }

    if (project.isCreationStatusError()) {
      GuiNotification.modalError(
        this.i18n.gettext('Siamo spiacenti ma la duplicazione non è andata a buon fine.')
      );
      return false;
    }

    if (project.isCreationStatusValid()) {
      return true;
    }

    Logger.error('CartController.isProjectCreationStatusValid', {
      error: 'Project has creation status not defined',
      project
    });
    return false;
  }

  async updateColorCorrection(preorder, colorCorrectionCode, project) {
    if (project.state === conf.settings.PROJECT_STATE_CART) {
      this.cartHeader.isLoading = true;
    }
    this.addProjectToLoadingCollection(preorder.projectId);
    this.render(this.context);

    const oldColorCorrectionCode = preorder.colorCorrectionCode;
    try {
      preorder.colorCorrectionCode = colorCorrectionCode;
      await preorder.save();
    } catch (err) {
      preorder.colorCorrectionCode = oldColorCorrectionCode;
      Logger.error(
        'CartCheckoutBaseController.updateColorCorrection - unable to update colorCorrectionCode',
        {
          error: err,
          preorder,
          colorCorrectionCode
        }
      );
      GuiNotification.modalError(
        this.i18n.gettext('Aggiornamento della correzione colore è fallito '),
        'Error detail: CartController.updateColorCorrection - update colorCorrectionCode'
      );
    } finally {
      // Non gestire l'errore perche' se fallisce la loadOrderHeader non ho i prezzi aggiornati
      if (project.state === conf.settings.PROJECT_STATE_CART) {
        // await Promise.all([
        //   this.getCartProjects(),
        //   this.loadCartHeader()
        // ]);
        await this.loadCartHeader();
      } else {
        await this.getBucketProject(project, false);
      }

      this.cartHeader.isLoading = false;
      this.removeProjectFromLoadingCollection(preorder.projectId);
      this.render(this.context);
    }
  }
}
