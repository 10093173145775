import { components } from 'outlinejs';
import React from 'react';

export class EmptyCart extends components.BaseComponent {
  onAddProductClick() {
    this.props.delegate.goToProductList();
  }

  render() {
    const { bucketProjectsCount } = this.props;

    if (bucketProjectsCount === 0) {
      return (
        <div className="empty-cart-container col-xs-12">
          <h1 className="cart-main-title">
            <br />
            {this.i18n.gettext('Il tuo carrello è vuoto')}
          </h1>
          <div className="col-lg-4 col-lg-offset-4 col-md-4 col-md-offset-4 col-sm-6 col-sm-offset-3 col-xs-12">
            <button
              className="btn btn-default btn-block"
              onClick={this.onAddProductClick.bind(this)}>
              {this.i18n.gettext('Aggiungi prodotti')}
            </button>
          </div>
        </div>
      );
    }
    return (
      <div className="empty-cart-container col-xs-12">
        <div className="empty-cart-img">
          <h1 className="cart-main-title">
            <br />
            {this.i18n.gettext("Aggiungi prodotti sottostanti all'ordine")}
          </h1>
        </div>
      </div>
    );
  }
}
