import React from 'react';
import classNames from 'classnames';

import LazyLoad from 'react-lazyload';
import { components } from 'outlinejs';
import { AeNotify } from '@photosi/albumepoca-ui';

import { EpocaBaseContentView } from '../core/views';
import { ProjectItem } from './components/projectItem';
import { EmptyCart } from './components/emptyCart';
import { ProjectComponent } from './components/projectComponent';
import { OrderSummary } from '../core/components/orderSummary';
import { Bundle } from './components/bundle';
import { PromotionsBanner } from './components/promotionsBanner';

import './styles/main.scss';
import { getLocalForage, setLocalForage } from '../core/storage';

class CartProjectsView extends components.BaseComponent {
  render() {
    if (this.props.cartProjects.collection.length === 0) {
      if (this.props.bucketProjects.firstLoad) {
        return null;
      }
      return (
        <div
          ref={(elem) => {
            this.cartProductsSectionElement = elem;
          }}
          className="cart-products-section">
          <EmptyCart
            bucketProjectsCount={this.props.bucketProjects.collection.length}
            delegate={this.props.delegate}
          />
        </div>
      );
    }

    let cartContainerClass;
    if (
      (this.props.cartHeader.validCoupon &&
        this.props.cartHeader.model.couponCodes &&
        this.props.cartHeader.model.couponCodes.length > 0) ||
      (this.props.contentIsLoading &&
        this.cartProductsSectionElement &&
        this.cartProductsSectionElement.classList.contains('cart-coupon-active'))
    ) {
      cartContainerClass = classNames('cart-products-section', 'cart-coupon-active');
    } else {
      cartContainerClass = classNames('cart-products-section');
    }

    return (
      <div
        ref={(elem) => {
          this.cartProductsSectionElement = elem;
        }}
        className={cartContainerClass}>
        <div className="col-xs-12">
          <div className="row cart-products-head">
            <div className="hidden-xs col-sm-12">
              <h5 className="cart-products-title text-uppercase">
                {this.i18n.gettext("Prodotti nell'ordine")}
              </h5>
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12">
              <div className="row cart-products-tbody">
                <div className="col-xs-12">
                  {this.props.cartProjects.collection.map((project) => (
                    <ProjectComponent
                      project={project}
                      delegate={this.props.delegate}
                      key={project.id}
                    />
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

/**
 * Bucket Projects List View
 * */
class BucketProjectsView extends components.BaseComponent {
  //eslint-disable-line
  constructor(props) {
    super(props);
    this.loadMoreBucketProjects = this.loadMoreBucketProjects.bind(this);
  }

  loadMoreBucketProjects() {
    this.props.delegate.loadMoreBucketProjects();
  }

  render() {
    if (this.props.bucketProjects.collection.length === 0) {
      return <div />;
    }
    const { bottomreached } = this.props.bucketProjects;
    const bucketContainerClass = classNames('bucket-products-section', {
      'loading-widget': this.props.bucketProjects.isLoading
    });

    return (
      <div className={bucketContainerClass}>
        <div className="col-xs-12">
          <div className="row bucket-products-head">
            <div className="col-xs-12">
              <h5 className="bucket-products-title text-uppercase">
                {this.i18n.gettext("Prodotti non presenti nell'ordine")}
              </h5>
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12 bucket-products-thead">
              <div className="row">
                <div className="col-xs-2 col-sm-2 bucket-products-td">&nbsp;</div>
                <div className="col-sm-3 bucket-products-td mobile-hide">
                  {this.i18n.gettext('Nome prodotto')}
                </div>
                <div className="col-xs-8 col-sm-3 bucket-products-td">
                  {this.i18n.gettext('Titolo progetto')}
                </div>
                <div className="col-sm-2 bucket-products-td mobile-hide">
                  {this.i18n.gettext('Prezzo totale')}
                </div>
                <div className="col-xs-2 col-sm-2 bucket-products-td">&nbsp;</div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12 bucket-products-tbody">
              {this.props.bucketProjects.collection.map((project) => (
                <LazyLoad key={project.id} once>
                  <ProjectItem project={project} delegate={this.props.delegate} />
                </LazyLoad>
              ))}
            </div>
          </div>

          <div className="row">
            <div className="col-xs-8 col-xs-offset-2 col-md-4 col-md-offset-4">
              {!bottomreached && (
                <button
                  className="btn btn-default btn-block btn-load-more"
                  onClick={this.loadMoreBucketProjects}>
                  {this.i18n.gettext('Carica altri progetti')}
                </button>
              )}
              {bottomreached && (
                <p style={{ marginTop: 20 }}>
                  {this.i18n.gettext('Stai visualizzando tutti i prodotti')}
                </p>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

/**
 * Cart Grid Responsive Layout
 */
class FlexAeCartGridLayout extends components.BaseComponent {
  constructor(props) {
    super(props);

    this.onCouponAdd = this.onCouponAdd.bind(this);
    this.onCouponRemove = this.onCouponRemove.bind(this);
    this.onMainButtonClick = this.onMainButtonClick.bind(this);
    this.onAddProductClick = this.onAddProductClick.bind(this);
    this.onPromotionBannerAdd = this.onPromotionBannerAdd.bind(this);

    this.state = {
      showNotify: false
    };
  }

  async componentDidMount() {
    const notificationDismissed = await getLocalForage('notificationDismiss');
    this.setState({
      showNotify: !notificationDismissed
    });
  }

  dismissNotification() {
    this.closeNotification();
    setLocalForage('notificationDismiss', true);
  }

  closeNotification() {
    this.setState({
      showNotify: false
    });
  }

  onCouponAdd(coupon) {
    this.props.delegate.addCoupon(coupon);
  }

  onCouponRemove(coupon) {
    this.props.delegate.removeCoupon(coupon);
  }

  onMainButtonClick() {
    this.props.delegate.goToCheckout();
  }

  onAddProductClick() {
    this.props.delegate.goToProductList();
  }

  onPromotionBannerAdd(couponCode) {
    this.props.delegate.applyPromotion(couponCode);
  }

  render() {
    return (
      <div className="cart_flex_grid row">
        <div className="cart_order_products col-lg-9">
          <CartProjectsView {...this.props} />
        </div>
        <div className="cart_order_summary col-lg-3">
          <OrderSummary
            numberOfProducts={this.props.cartHeader.model.numberOfProducts}
            subTotalWithoutVoucherValue={
              this.props.cartHeader.model.formattedSubTotalWithoutVoucher
            }
            voucherValue={this.props.cartHeader.model.formattedTotalVoucher}
            hasDiscount={this.props.cartHeader.model.totalVoucher > 0}
            subTotal={this.props.cartHeader.model.formattedSubTotal}
            vat={this.props.cartHeader.model.formattedSubTotalVat}
            price={this.props.cartHeader.model.formattedSubTotalWithVat}
            coupons={this.props.cartHeader.model.couponCodes}
            couponsLimit={this.props.cartHeader.model.couponsLimit}
            couponValid={this.props.cartHeader.validCoupon}
            onCouponAdd={this.onCouponAdd}
            onCouponRemove={this.onCouponRemove}
            onMainButtonClick={this.onMainButtonClick}
            onAddProductClick={this.onAddProductClick}
            isLoading={this.props.cartHeader.isLoading}
          />
          <PromotionsBanner
            coupons={this.props.promotions}
            activeCoupons={this.props.cartHeader.model.couponCodes}
            onPromotionBannerAdd={this.onPromotionBannerAdd}
          />
          <Bundle {...this.props} />
        </div>
        <div className="cart_products_list col-lg-9" id={'bucket'}>
          <BucketProjectsView {...this.props} />
        </div>
        <AeNotify
          isActive={this.state.showNotify}
          message={this.i18n.gettext(
            'Vuoi creare una copia per genitori e amici?   \rClicca sul tasto DUPLICA e potrai realizzare un nuovo prodotto partendo dall’esistente.'
          )}
          onClick={this.dismissNotification.bind(this)}
          onDismiss={this.closeNotification.bind(this)}
          action={this.i18n.gettext('Ho capito.')}
          dismissAfter={10000}
        />
      </div>
    );
  }
}

/**
 * Main Cart content View.
 *
 * NOTE: render method is defined in EpocaBaseContentView
 * */
export class CartContentView extends EpocaBaseContentView {
  renderContent() {
    return <FlexAeCartGridLayout {...this.props} />;
  }
}
