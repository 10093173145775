import { components, conf } from 'outlinejs';
import React from 'react';

import AeGuideAlbum from '../../core/media/images/ae_guide/ae_guide_album.jpg';
import AeGuideEventBook from '../../core/media/images/ae_guide/ae_guide_event_book.jpg';

/**
 * Bundle component.
 */
export class Bundle extends components.BaseComponent {
  constructor(props) {
    super(props);
  }

  async handleClick(aeGuideType) {
    await this.props.delegate.addAEGuide(aeGuideType);
  }

  renderAeBanner() {
    return (
      <div className="row padding-bs promo-group">
        <div className="col-lg-12 lg-description no-bs-padding aeguide-banner-promo">
          <span className="">{this.i18n.gettext('Samples of materials and customizations')}</span>
        </div>
        <div className="col-xs-12 mobile-description no-bs-padding aeguide-banner-promo-mobile">
          <span className="">{this.i18n.gettext('Samples of materials and customizations')}</span>
        </div>
      </div>
    );
  }

  renderAeGuideBanner() {
    return (
      <div className="row padding-bs">
        <div className="col-xs-12 no-bs-padding mobile-banner-group-section">
          <div className="col-lg-12 col-md-4 col-sm-4 mobile-banner-section no-bs-padding border-bottom">
            <img alt={'AEGuide Album'} src={AeGuideAlbum} />
          </div>
        </div>
        <div className="col-lg-12 col-md-6 col-lg-pull-0 col-md-pull-1 col-sm-8 col-xs-12 padding-banner-group-section pull-right">
          <div className="col-xs-12 col-xs-offset-0 col-sm-10 col-sm-offset-1 col-md-12 col-md-offset-0">
            <div className="row banner-group_main_action">
              <div className="col-xs-12">
                <button
                  className="btn btn-primary btn-block"
                  onClick={this.handleClick.bind(
                    this,
                    conf.settings.PRODUCT_TYPE_ALBUM.toUpperCase()
                  )}>
                  <span>{this.i18n.gettext("aggiungi all'ordine")}</span>
                </button>
              </div>
            </div>
            <div className="row ghost-btn-section">
              <div className="col-xs-12">
                <a className="ghost-btn">{this.i18n.gettext('Scopri di più')}</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderEventBookAeGuideBanner() {
    return (
      <div className="row padding-bs">
        <div className="col-xs-12 no-bs-padding">
          <div className="col-lg-12 col-md-4 col-sm-4  mobile-banner-section no-bs-padding border-bottom">
            <img alt={'AEGuide EventBook'} src={AeGuideEventBook} />
          </div>
        </div>
        <div className="col-lg-12 col-md-6 col-lg-pull-0 col-md-pull-1 col-sm-8 col-xs-12 padding-banner-group-section pull-right">
          <div className="col-xs-12 col-xs-offset-0 col-sm-10 col-sm-offset-1 col-md-12 col-md-offset-0">
            <div className="row banner-group_main_action">
              <div className="col-xs-12">
                <button
                  className="btn btn-primary btn-block"
                  onClick={this.handleClick.bind(
                    this,
                    conf.settings.PRODUCT_TYPE_EVENT_BOOK.toUpperCase()
                  )}>
                  <span>{this.i18n.gettext("aggiungi all'ordine")}</span>
                </button>
              </div>
            </div>
            <div className="row ghost-btn-section">
              <div className="col-xs-12">
                <a className="ghost-btn">{this.i18n.gettext('Scopri di più')}</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className="row">
        <div className="col-xs-12">
          <div className="banner-group-header">{this.renderAeBanner()}</div>
          <div className="banner-group">{this.renderEventBookAeGuideBanner()}</div>
          <div className="banner-group">{this.renderAeGuideBanner()}</div>
        </div>
      </div>
    );
  }
}
