import { conf } from 'outlinejs';
import { BaseModel } from '../core/models';

export class AEGuideConfiguration extends BaseModel {
  getAbsoluteUrl() {
    return `${this.urlRoot + this.id}/`;
  }

  get urlRoot() {
    return conf.settings.AEGUIDE_CONFIGURATIONS_URL;
  }

  get id() {
    return this.get('id');
  }

  get productType() {
    return this.get('productType');
  }

  get productName() {
    return this.get('productName');
  }

  get shopCode() {
    return this.get('shopCode');
  }

  set shopCode(value) {
    this.set('shopCode', value);
  }

  get aeGuideType() {
    return this.get('aeGuideType');
  }

  set aeGuideType(value) {
    this.set('aeGuideType', value);
  }

  get previewImageUrl() {
    return this.get('previewImageUrl');
  }

  get previewImageFileId() {
    return this.get('previewImageFileId');
  }
}
