import { components } from 'outlinejs';
import React from 'react';

export class FullServiceDesignInfo extends components.BaseComponent {
  convertDate(inputFormat, language) {
    let date;

    function pad(s) {
      return s < 10 ? `0${s}` : s;
    }

    const d = new Date(inputFormat);
    date =
      language === 'en'
        ? [pad(d.getMonth() + 1), pad(d.getDate()), d.getFullYear()].join('/')
        : [pad(d.getDate()), pad(d.getMonth() + 1), d.getFullYear()].join('/');
    return date;
  }

  render() {
    const { date, language } = this.props;
    const fsdDate = this.convertDate(date, language);

    return (
      <div className="row">
        <div className="col-xs-12">
          <div className="fsdLabel">
            <div className="row">
              <div className="col-md-2 col-xs-12 alert-icon text-center">
                <i className="fa color-primary fa-exclamation-triangle" />
              </div>
              <div className="col-sm-9 col-xs-12 alert-text pull-right">
                {this.i18n.gettext('Impaginazione in corso')}
              </div>
            </div>
            {this.i18n.gettext('Riabiliteremo le modifiche al termine del processo')}
          </div>
          <div className="fsdDateLabel">
            <div className="row">
              <div className="col-md-12 col-xs-12 alert-text">
                {this.i18n.gettext('Data di richiesta')}
              </div>
              <div className="col-md-12 col-xs-12 alert-text">{fsdDate}</div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
