import { components, conf } from 'outlinejs';
import React, { Fragment } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';

/**
 * Product item buttons
 */
class BaseProductButton extends components.BaseComponent {
  render() {
    const { icon, label, action, green, disabled, dataAction, disableBlock, orderClass } =
      this.props;
    const iconClass = classNames('fa color-primary', icon);
    const buttonClass = classNames(
      'btn btn-block btn-ae-icon',
      { disabled },
      { greenBtn: green },
      { 'blocked-project-disabled': disableBlock }
    );
    return (
      <div className={`col-lg-4 col-sm-6 col-xs-12 no-padding-col-dx ${orderClass || ''}`}>
        <div className="item-button">
          <button className={buttonClass} onClick={action} data-action={dataAction}>
            <i className={iconClass} />
            <span className="text-lowercase">{label}</span>
          </button>
        </div>
      </div>
    );
  }
}

BaseProductButton.propTypes = {
  icon: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  action: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  green: PropTypes.bool,
  disableBlock: PropTypes.bool,
  dataAction: PropTypes.string,
  orderClass: PropTypes.string
};

BaseProductButton.defaultProps = {
  disabled: false,
  green: false,
  disableBlock: false,
  dataAction: ''
};

export class EditConfigurationButton extends components.BaseComponent {
  async onEditConfiguration() {
    this.props.delegate.editPreorderConfiguration(this.props.preorder);
  }

  render() {
    const { editConfigurationUrl, configurationStatus, configurationId } = this.props.preorder;

    if (!editConfigurationUrl) {
      return null;
    }

    let configurationButtonIcon = 'fa-pencil-square-o';
    if (configurationStatus && configurationStatus !== 'VALID') {
      configurationButtonIcon = 'fa-exclamation-triangle red';
    }

    return (
      <Fragment>
        <div data-tip={this.i18n.gettext('Edit configuration tooltip')}>
          <BaseProductButton
            icon={configurationButtonIcon}
            label={this.i18n.gettext('modifica configurazione')}
            dataAction={`edit_configuration_${configurationId}`}
            action={this.onEditConfiguration.bind(this)}
          />
        </div>
        <ReactTooltip className="ae-tooltip" />
      </Fragment>
    );
  }
}

export class ShareConfigurationButton extends components.BaseComponent {
  showShareProjectButton(productType) {
    let result;
    switch (productType) {
      case conf.settings.PRODUCT_TYPE_MATTED_PRINTS:
        result = false;
        break;
      default:
        result = true;
    }

    return result;
  }

  async onViewPreview() {
    const status = await this.props.delegate.isProjectCreationStatusValid(
      this.props.project,
      this.props.preorder
    );
    if (status) {
      this.props.delegate.viewPreview(this.props.preorder);
    }
  }

  render() {
    const { setServiceUrl, configurationId, productType, layoutStatus } = this.props.preorder;
    const { canBeShared } = this.props.project;
    let disabled = !canBeShared;
    if (productType === conf.settings.PRODUCT_TYPE_HOME_DECOR && layoutStatus !== 'ERROR') {
      disabled = false;
    }

    if (
      (setServiceUrl && this.showShareProjectButton(productType)) ||
      productType === conf.settings.PRODUCT_TYPE_HOME_DECOR
    ) {
      // eslint-disable-line
      return (
        <Fragment>
          <div data-tip={this.i18n.gettext('Share configuration tooltip')}>
            <BaseProductButton
              icon="fa-eye"
              label={this.i18n.gettext('anteprima impaginazione')}
              dataAction={`layout_preview_configuration_${configurationId}`}
              action={this.onViewPreview.bind(this)}
              disabled={disabled}
              orderClass="col-lg-pull-4"
            />
          </div>
          <ReactTooltip className="ae-tooltip" />
        </Fragment>
      );
    }
    return null;
  }
}

export class AddRemoveCartButton extends components.BaseComponent {
  get isVisible() {
    const { productType } = this.props.preorder;

    switch (productType) {
      case conf.settings.PRODUCT_TYPE_MATTED_PRINTS_STAND:
        return false;
      case conf.settings.PRODUCT_TYPE_AE_GUIDE:
        return false;
      case conf.settings.PRODUCT_TYPE_USB_DRIVE_PACKAGING:
        return true;
      default:
        return productType.toLowerCase().indexOf('packaging') === -1;
    }
  }

  async onProjectStateChange() {
    const status = await this.props.delegate.isProjectCreationStatusValid(
      this.props.project,
      this.props.preorder
    );
    if (status) {
      this.props.addRemoveProjectFromCart();
    }
  }

  render() {
    const { canBeOrdered, state, id } = this.props.project;
    const disabledStatus = !canBeOrdered;

    if (this.isVisible) {
      return (
        <BaseProductButton
          icon={
            state === conf.settings.PROJECT_STATE_CART
              ? 'fa-share-square-o fa-rotate-90'
              : 'fa-share-square-o fa-rotate-270'
          }
          label={
            state === conf.settings.PROJECT_STATE_CART
              ? this.i18n.gettext("rimuovi dall'ordine")
              : this.i18n.gettext("aggiungi all'ordine")
          }
          dataAction={
            state === conf.settings.PROJECT_STATE_CART
              ? `remove_project_from_cart_${id}`
              : `add_project_to_cart_${id}`
          }
          action={this.onProjectStateChange.bind(this)}
          disabled={disabledStatus}
          green={state === conf.settings.PROJECT_STATE_BUCKET && !disabledStatus}
          orderClass="col-lg-push-4"
        />
      );
    }
    return null;
  }
}

export class EditLayoutButton extends components.BaseComponent {
  get isVisible() {
    const { setServiceUrl, serviceConfigurationTypeCode, productType } = this.props.preorder;

    if (productType === conf.settings.PRODUCT_TYPE_HOME_DECOR) {
      return true;
    }

    return setServiceUrl || serviceConfigurationTypeCode;
  }

  async onEditLayout() {
    const status = await this.props.delegate.isProjectCreationStatusValid(
      this.props.project,
      this.props.preorder
    );
    if (status) {
      this.props.delegate.editPreorderLayout(this.props.preorder);
    }
  }

  getEditLayoutLabel(productType) {
    let label;
    switch (productType) {
      case conf.settings.PRODUCT_TYPE_MATTED_PRINTS:
        label = this.i18n.gettext('modifica inquadratura');
        break;
      case conf.settings.PRODUCT_TYPE_HOME_DECOR:
        label = this.i18n.gettext('modifica inquadratura');
        break;
      default:
        label = this.i18n.gettext('modifica impaginazione');
    }
    return label;
  }

  render() {
    const {
      setServiceUrl,
      serviceConfigurationTypeCode,
      layoutStatus,
      filesStatus,
      configurationId,
      productType
    } = this.props.preorder;

    if (this.isVisible) {
      let editButtonIcon = 'my-fa-openbook';
      let editButtonLabel = this.getEditLayoutLabel(productType);
      if (
        layoutStatus === 'ERROR' ||
        filesStatus === 'ERROR' ||
        !setServiceUrl ||
        !serviceConfigurationTypeCode
      ) {
        editButtonIcon = 'fa-exclamation-triangle red';
      }
      if (!serviceConfigurationTypeCode) {
        editButtonLabel = this.i18n.gettext('crea impaginazione');
      }

      if (productType === conf.settings.PRODUCT_TYPE_HOME_DECOR) {
        editButtonIcon =
          layoutStatus === 'ERROR' ? 'fa-exclamation-triangle red' : 'my-fa-openbook';
        editButtonLabel = this.i18n.gettext('modifica inquadratura');
      }

      return (
        <BaseProductButton
          icon={editButtonIcon}
          label={editButtonLabel}
          dataAction={`edit_layout_configuration_${configurationId}`}
          action={this.onEditLayout.bind(this)}
          orderClass={
            productType === conf.settings.PRODUCT_TYPE_MATTED_PRINTS ? 'col-lg-pull-4' : ''
          }
        />
      );
    }
    return null;
  }
}

export class DeleteProjectButton extends components.BaseComponent {
  get isVisible() {
    const { productType } = this.props.preorder;

    switch (productType) {
      case conf.settings.PRODUCT_TYPE_MATTED_PRINTS_STAND:
        return false;
      case conf.settings.PRODUCT_TYPE_USB_DRIVE_PACKAGING:
        return true;
      default:
        return productType.toLowerCase().indexOf('packaging') === -1;
    }
  }

  async onDeleteProject() {
    await this.props.delegate.isProjectCreationStatusValid(this.props.project, this.props.preorder);
    if (!this.props.project.isDuplicationInProgress()) {
      this.props.delegate.modalDeleteProject(this.props.project, this.props.preorder);
    }
  }

  render() {
    const { productType } = this.props.preorder;
    const { id } = this.props.project;

    const deleteClassName = classNames({
      'col-sm-offset-6 col-lg-offset-0': [
        conf.settings.PRODUCT_TYPE_MATTED_PRINTS,
        conf.settings.PRODUCT_TYPE_HOME_DECOR
      ].includes(productType),
      'col-lg-pull-4': productType === conf.settings.PRODUCT_TYPE_USB_DRIVE_PACKAGING
    });

    if (this.isVisible) {
      return (
        <BaseProductButton
          icon="fa-trash-o red"
          label={this.i18n.gettext('elimina prodotto')}
          dataAction={`delete_project_${id}`}
          action={this.onDeleteProject.bind(this)}
          orderClass={deleteClassName}
        />
      );
    }
    return null;
  }
}

export class DuplicateProjectButton extends components.BaseComponent {
  async onShowDuplicateDialog() {
    const status = await this.props.delegate.isProjectCreationStatusValid(
      this.props.project,
      this.props.preorder
    );
    if (status) {
      this.props.delegate.modalDuplicationProject(this.props.project);
    }
  }

  render() {
    const { setServiceUrl } = this.props.preorder;
    const { canBeDuplicated, id } = this.props.project;

    if (setServiceUrl) {
      return (
        <Fragment>
          <div data-tip={this.i18n.gettext('Duplicate configuration tooltip')}>
            <BaseProductButton
              icon="fa-files-o"
              label={this.i18n.gettext('duplicate product')}
              dataAction={`duplicate_project_${id}`}
              action={this.onShowDuplicateDialog.bind(this)}
              disabled={!canBeDuplicated}
            />
          </div>
          <ReactTooltip className="ae-tooltip" />
        </Fragment>
      );
    }
    return null;
  }
}
