import { components } from 'outlinejs';
import React, { Fragment } from 'react';
import { DesignServicePriceSet } from '../../core/components/designServicePriceSet';

export class DesignServiceInfo extends components.BaseComponent {
  render() {
    const { designServiceFormattedPrice, designServiceFormattedDiscountedPrice } =
      this.props.preorder;

    return (
      <Fragment>
        <div className="col-xs-6">
          <div className="row">
            <div className="col-xs-12 text-center">
              <span className="product-info-service-title">
                <b>{this.i18n.gettext('impaginazione')}</b>
              </span>
            </div>
          </div>
        </div>

        <div className="col-xs-6">
          <div className="row">
            <div className="col-xs-12 total_prices">
              <div className="price">
                {designServiceFormattedPrice && (
                  <DesignServicePriceSet
                    price={designServiceFormattedPrice}
                    discountedPrice={designServiceFormattedDiscountedPrice}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}
