import { components, conf } from 'outlinejs';
import classNames from 'classnames';
import React from 'react';
import { ProductComponent } from './productComponent';

const ProjectItemDivider = () => <div className="product-item-divider" />;

export class ProjectComponent extends components.BaseComponent {
  constructor(props) {
    super(props);
  }

  render() {
    const { project, delegate } = this.props;

    let isFullServiseDesign = null;
    project.preorderCollection.forEach((preorder) => {
      if (
        preorder.serviceConfigurationTypeCode &&
        preorder.serviceConfigurationTypeCode.toLowerCase() ===
          conf.settings.PROJECT_FULL_SERVICE_DESIGN.toLowerCase()
      ) {
        isFullServiseDesign = true;
      }
    });

    const selectedProjectId = this.props.delegate.selectedProject;
    const self = this;

    const componentClass = classNames(
      'row',
      'project-component',
      { 'loading-project-component': project.isPreorderCollectionLoading },
      {
        loading: this.props.delegate.cartProjectComponentIsLoading(project.id)
      },
      {
        'blocked-project':
          !project.canBeModified && isFullServiseDesign && !this.props.delegate.impersonationActive
      }
    );

    return (
      <div className={componentClass}>
        <div className="col-xs-12">
          {project.preorderCollection.map((preorder, index) => (
            <div
              ref={(node) => {
                if (preorder.projectId.toString() === selectedProjectId && node) {
                  self.selectedProject = node;
                }
              }}
              key={preorder.id}>
              <ProductComponent preorder={preorder} project={project} delegate={delegate} />
              {index < project.preorderCollection.length - 1 ? <ProjectItemDivider /> : null}
            </div>
          ))}
        </div>
      </div>
    );
  }
}
