import { components, conf } from 'outlinejs';
import React from 'react';
import {
  AddRemoveCartButton,
  DeleteProjectButton,
  DuplicateProjectButton,
  EditConfigurationButton,
  EditLayoutButton,
  ShareConfigurationButton
} from './productButtons';

class BaseProductActions extends components.BaseComponent {
  constructor(props) {
    super(props);
  }

  addRemoveProjectFromCart() {
    if (this.props.project.state === conf.settings.PROJECT_STATE_CART) {
      this.props.delegate.removeProjectFromCart(this.props.project);
    } else if (this.props.project.canBeOrdered) {
      this.props.delegate.addProjectToCart(this.props.project);
    }
  }

  render() {
    throw new Error('"render()" function must be implemented in class');
  }
}

class MattedPrintsProductActions extends BaseProductActions {
  render() {
    return (
      <div className="row order_all_btns">
        <div className="col-xs-12 cart-project-item-buttons no-padding-col">
          <EditConfigurationButton
            preorder={this.props.preorder}
            project={this.props.project}
            delegate={this.props.delegate}
          />
          <AddRemoveCartButton
            preorder={this.props.preorder}
            project={this.props.project}
            delegate={this.props.delegate}
            addRemoveProjectFromCart={this.addRemoveProjectFromCart.bind(this)}
          />
          <EditLayoutButton
            preorder={this.props.preorder}
            project={this.props.project}
            delegate={this.props.delegate}
          />
          <DuplicateProjectButton
            preorder={this.props.preorder}
            project={this.props.project}
            delegate={this.props.delegate}
          />
          <DeleteProjectButton
            preorder={this.props.preorder}
            project={this.props.project}
            delegate={this.props.delegate}
          />
        </div>
      </div>
    );
  }
}

class HomeDecorProductActions extends BaseProductActions {
  render() {
    return (
      <div className="row order_all_btns">
        <div className="col-xs-12 cart-project-item-buttons no-padding-col">
          <EditConfigurationButton
            preorder={this.props.preorder}
            project={this.props.project}
            delegate={this.props.delegate}
          />
          <AddRemoveCartButton
            preorder={this.props.preorder}
            project={this.props.project}
            delegate={this.props.delegate}
            addRemoveProjectFromCart={this.addRemoveProjectFromCart.bind(this)}
          />
          <ShareConfigurationButton
            preorder={this.props.preorder}
            project={this.props.project}
            delegate={this.props.delegate}
          />
          <EditLayoutButton
            preorder={this.props.preorder}
            project={this.props.project}
            delegate={this.props.delegate}
          />
          <DeleteProjectButton
            preorder={this.props.preorder}
            project={this.props.project}
            delegate={this.props.delegate}
          />
        </div>
      </div>
    );
  }
}

class DefaultProductActions extends BaseProductActions {
  render() {
    return (
      <div className="row order_all_btns">
        <div className="col-xs-12 cart-project-item-buttons no-padding-col">
          <EditConfigurationButton
            preorder={this.props.preorder}
            project={this.props.project}
            delegate={this.props.delegate}
          />
          <AddRemoveCartButton
            preorder={this.props.preorder}
            project={this.props.project}
            delegate={this.props.delegate}
            addRemoveProjectFromCart={this.addRemoveProjectFromCart.bind(this)}
          />
          <ShareConfigurationButton
            preorder={this.props.preorder}
            project={this.props.project}
            delegate={this.props.delegate}
          />
          <DuplicateProjectButton
            preorder={this.props.preorder}
            project={this.props.project}
            delegate={this.props.delegate}
          />
          <EditLayoutButton
            preorder={this.props.preorder}
            project={this.props.project}
            delegate={this.props.delegate}
          />
          <DeleteProjectButton
            preorder={this.props.preorder}
            project={this.props.project}
            delegate={this.props.delegate}
          />
        </div>
      </div>
    );
  }
}

export class ProductActions extends components.BaseComponent {
  constructor(props) {
    super(props);
  }

  addRemoveProjectFromCart() {
    if (this.props.project.state === conf.settings.PROJECT_STATE_CART) {
      this.setState({ translatingDown: true }); // todo NOT WORKING
      setTimeout(() => {
        this.props.delegate.removeProjectFromCart(this.props.project);
      }, 300);
    } else if (this.props.project.canBeOrdered) {
      this.setState({ translatingUp: true });
      setTimeout(() => {
        this.props.delegate.addProjectToCart(this.props.project);
      }, 300);
    }
  }

  render() {
    const { preorder, project, delegate } = this.props;
    const { productType } = preorder;

    switch (productType) {
      case conf.settings.PRODUCT_TYPE_MATTED_PRINTS:
        return (
          <MattedPrintsProductActions preorder={preorder} project={project} delegate={delegate} />
        );
      case conf.settings.PRODUCT_TYPE_HOME_DECOR:
        return (
          <HomeDecorProductActions preorder={preorder} project={project} delegate={delegate} />
        );
      default:
        return <DefaultProductActions preorder={preorder} project={project} delegate={delegate} />;
    }
  }
}
