import { components, conf } from 'outlinejs';
import React from 'react';
import classNames from 'classnames';
import { ProductActions } from './productActions';
import { ProductPreview } from './productPreview';
import { ProductDescription } from './productDescription';
import { FullServiceDesignInfo } from './fullServiceDesignInfo';
import { ProductInfo } from './productInfo';
import { ProductNotAvailable } from './productNotAvailable';

class ProductOutOfStock extends components.BaseComponent {
  render() {
    return <div className="item_out_of_stock"> {this.i18n.gettext('product out of stock')}</div>;
  }
}

export class ProductComponent extends components.BaseComponent {
  render() {
    const { canBeModified, preorders, isOutOfProduction } = this.props.project;
    const {
      id,
      productType,
      serviceConfigurationTypeCode,
      requestFullServiceDesignDate,
      isOutOfStock
    } = this.props.preorder;
    const projectId = this.props.project.id;
    const showFullServiceDesignInfo =
      !canBeModified &&
      preorders &&
      id === preorders[0] &&
      serviceConfigurationTypeCode &&
      serviceConfigurationTypeCode.toLowerCase() ===
        conf.settings.PROJECT_FULL_SERVICE_DESIGN.toLowerCase();
    const productNotAvailable =
      isOutOfProduction && !(productType.toLowerCase().indexOf('packaging') > 0);
    const projectClass = classNames('row', 'cart-project-item');

    return (
      <div className="row">
        <div className="col-xs-12">
          <div ref={projectId} className={projectClass} data-project-id={projectId}>
            <div className="col-sm-3 blocked-project-disabled">
              <ProductPreview
                preorder={this.props.preorder}
                project={this.props.project}
                delegate={this.props.delegate}
              />
              {isOutOfStock && <ProductOutOfStock language={this.request.language} />}
              {showFullServiceDesignInfo && (
                <FullServiceDesignInfo
                  date={requestFullServiceDesignDate}
                  language={this.request.language}
                />
              )}
            </div>
            <div className="col-sm-9 cart_flex_grid">
              <ProductInfo
                preorder={this.props.preorder}
                project={this.props.project}
                delegate={this.props.delegate}
              />
              <ProductDescription
                preorder={this.props.preorder}
                project={this.props.project}
                delegate={this.props.delegate}
                openDescription={showFullServiceDesignInfo}
              />
              {productNotAvailable && <ProductNotAvailable />}
              <ProductActions
                preorder={this.props.preorder}
                project={this.props.project}
                delegate={this.props.delegate}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
