import { components, conf } from 'outlinejs';
import React from 'react';
import { ColorCorrectionInfo } from './colorCorrectionInfo';
import { PriceSet } from '../../core/components/orderSummary';
import { QuantityInput } from '../../core/components/quantityInput';
import { DesignServiceInfo } from './designServiceInfo';

export class ProductInfo extends components.BaseComponent {
  constructor(props) {
    super(props);
    this.onQuantityChange = this.onQuantityChange.bind(this);
  }

  get showProjectName() {
    const { productType } = this.props.preorder;

    switch (productType) {
      case conf.settings.PRODUCT_TYPE_AE_GUIDE:
        return false;
      default:
        return true;
    }
  }

  onQuantityChange(value) {
    if (value && value !== this.props.preorder.quantity) {
      this.props.delegate.changeQuantity(this.props.project, this.props.preorder, value);
    }
  }

  render() {
    const {
      quantity,
      unitaryFormattedPrice,
      unitaryFullFormattedPrice,
      totalFormattedPrice,
      totalFullFormattedPrice,
      productName,
      colorCorrectionCode,
      designServiceCode
    } = this.props.preorder;
    const { name, colorCorrectionTypes } = this.props.project;
    const showColorCorrectionInfo =
      colorCorrectionCode && colorCorrectionTypes && colorCorrectionTypes.length > 0;

    return (
      <div className="row product-info-element">
        <div className="col-sm-9">
          <div className="row product-name mobile-show">
            <div className="col-xs-12">
              <h4 className="text-uppercase">{productName}</h4>
              {this.showProjectName && <h5 className="project-name">{name}</h5>}
            </div>
          </div>

          <div className="row product-info-prices">
            <div className="col-xs-12 col-sm-5">
              <div className="row">
                <div className="col-xs-4 mobile-show">
                  <span className="price-labels">{this.i18n.gettext('quantità')}</span>
                </div>
                <div className="col-xs-8 col-sm-12">
                  <span className="price-labels mobile-hide">{this.i18n.gettext('quantità')}</span>
                  <QuantityInput
                    quantity={quantity}
                    onQuantityChange={this.onQuantityChange}
                    onQuantityChangeInProgress={this.props.delegate.changeQuantityInProgress}
                  />
                </div>
              </div>
            </div>
            <div className="col-xs-12 col-sm-7 cart-project-prices-element">
              <div className="row">
                <div className="col-xs-12 col-sm-6 cart-project-prices-element">
                  <div className="row">
                    <div className="col-xs-6 mobile-show">
                      <span className="price-labels">{this.i18n.gettext('prezzo unitario')}</span>
                    </div>
                    <div className="col-xs-6 col-sm-12 unitary_prices">
                      <span className="price-labels mobile-hide">
                        {this.i18n.gettext('prezzo unitario')}
                      </span>
                      <PriceSet
                        fullprice={unitaryFullFormattedPrice}
                        price={unitaryFormattedPrice}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-xs-12 col-sm-6 cart-project-prices-element">
                  <div className="row">
                    <div className="col-xs-6 mobile-show">
                      <span className="price-labels">{this.i18n.gettext('importo')}</span>
                    </div>
                    <div className="col-xs-6 col-sm-12 total_prices">
                      <span className="price-labels mobile-hide">
                        {this.i18n.gettext('importo')}
                      </span>
                      <PriceSet fullprice={totalFullFormattedPrice} price={totalFormattedPrice} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row product-info-services">
            <div className="col-sm-5">
              <div className="row product-name mobile-hide">
                <div className="col-sm-12">
                  <h4 className="text-uppercase">{productName}</h4>
                  {this.showProjectName && <h5>{name}</h5>}
                </div>
              </div>
            </div>

            <div className="col-sm-7">
              {designServiceCode && (
                <div className="row design-service-info">
                  <DesignServiceInfo preorder={this.props.preorder} />
                </div>
              )}
              {showColorCorrectionInfo && (
                <div className="row color-correction-info">
                  <ColorCorrectionInfo
                    preorder={this.props.preorder}
                    project={this.props.project}
                    delegate={this.props.delegate}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
