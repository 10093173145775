import { components } from 'outlinejs';
import React from 'react';

export class ProductNotAvailable extends components.BaseComponent {
  render() {
    return (
      <div className="row">
        <div className="col-xs-12">
          <div className="alert-production">
            <i className="fa color-primary fa-exclamation-triangle red" />
            {this.i18n.gettext(
              'This project has at least one element that was discontinued. Please change your configuration to proceed with the order.'
            )}
          </div>
        </div>
      </div>
    );
  }
}
