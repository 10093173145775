import { components, conf } from 'outlinejs';

import React from 'react';

const DescriptionLoader = () => <div className="loading-description" />;

class ProductDescriptionContent extends components.BaseComponent {
  render() {
    const { description, serviceConfigurationTypeName, productType } = this.props.preorder;
    let descriptionContent = null;

    if (description) {
      const descriptionKeys = Object.keys(description);
      descriptionContent = descriptionKeys.map((descriptionKey, index) => (
        <span key={descriptionKey} className="cart-description-item">
          <b>{descriptionKey}</b>:{description[descriptionKey]}
          {index < descriptionKeys.length - 1 ? ' - ' : '.'}
        </span>
      ));
      if (
        serviceConfigurationTypeName &&
        productType !== conf.settings.PRODUCT_TYPE_MATTED_PRINTS
      ) {
        const descriptionKey = this.i18n.gettext('Tipo di impaginazione');
        descriptionContent.unshift(
          <span key={descriptionKey} className="cart-description-item">
            <b>{descriptionKey}</b>:{serviceConfigurationTypeName} -
          </span>
        );
      }
    }

    return (
      <div className="row cart-project-item-description">
        <div className="col-xs-12">
          <p>{descriptionContent}</p>
        </div>
      </div>
    );
  }
}

export class ProductDescription extends components.BaseComponent {
  constructor(props) {
    super(props);
    this.showDescription = this.showDescription.bind(this);
  }

  get isVisible() {
    const { productType } = this.props.preorder;

    switch (productType) {
      case conf.settings.PRODUCT_TYPE_MATTED_PRINTS_STAND:
        return false;
      case conf.settings.PRODUCT_TYPE_AE_GUIDE:
        return false;
      default:
        return true;
    }
  }

  componentDidMount() {
    const { openDescription } = this.props;
    const { descriptionVisible } = this.props.preorder;
    if (openDescription && !descriptionVisible) {
      this.showDescription();
    }
  }

  showDescription() {
    const { preorder, delegate } = this.props;
    delegate.toggleProductDescription(preorder);
    delegate.getProductDescription(preorder);
  }

  render() {
    if (!this.isVisible) {
      return null;
    }

    const { isDescriptionLoading, descriptionVisible } = this.props.preorder;

    const text = this.i18n.gettext('info prodotto');
    let icon = 'fa fa-angle-down';

    if (descriptionVisible) {
      icon = 'fa fa-angle-up';
    }

    return (
      <div className="row">
        <div className="col-xs-12 product-description-component">
          <div className="row">
            <div className="col-xs-12">
              <a onClick={this.showDescription}>
                <span>{text}</span>
                <i className={icon} />
              </a>
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12">
              {descriptionVisible && isDescriptionLoading && <DescriptionLoader />}
              {descriptionVisible && !isDescriptionLoading && (
                <ProductDescriptionContent preorder={this.props.preorder} />
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
