import { components } from 'outlinejs';
import React, { Fragment } from 'react';
import { ColorCorrectionPriceSet } from '../../core/components/colorCorrectionPriceSet';

export class ColorCorrectionInfo extends components.BaseComponent {
  async handleColorCorrectionChange(value) {
    await this.props.delegate.updateColorCorrection(this.props.preorder, value, this.props.project);
    this.setState({
      colorCorrection: this.props.preorder.colorCorrectionCode
    });
  }

  render() {
    const {
      colorCorrectionCode,
      colorCorrectionFormattedPrice,
      colorCorrectionFormattedDiscountedPrice
    } = this.props.preorder;
    const { colorCorrectionTypes } = this.props.project;

    return (
      <Fragment>
        <div className="col-xs-6">
          <div className="row">
            <div className="col-xs-12 text-center">
              <span className="product-info-service-title">
                <b>{this.i18n.gettext('correzione colore')}</b>
              </span>

              <div className="input-group input-group-color-correction">
                <span className="input-group-btn">
                  <button
                    className={
                      colorCorrectionCode === colorCorrectionTypes[0].id
                        ? 'btn btn-ae-icon active'
                        : 'btn btn-ae-icon'
                    }
                    onClick={this.handleColorCorrectionChange.bind(
                      this,
                      colorCorrectionTypes[0].id
                    )}>
                    {this.i18n.gettext('si')}
                  </button>
                </span>
                <span className="input-group-btn">
                  <button
                    className={
                      colorCorrectionCode === colorCorrectionTypes[1].id
                        ? 'btn btn-ae-icon active'
                        : 'btn btn-ae-icon'
                    }
                    onClick={this.handleColorCorrectionChange.bind(
                      this,
                      colorCorrectionTypes[1].id
                    )}>
                    {this.i18n.gettext('no')}
                  </button>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xs-6">
          <div className="row">
            <div className="col-xs-12 total_prices">
              <div
                className={colorCorrectionCode === colorCorrectionTypes[0].id ? 'price' : 'hidden'}>
                {colorCorrectionFormattedPrice && (
                  <ColorCorrectionPriceSet
                    price={colorCorrectionFormattedPrice}
                    discountedPrice={colorCorrectionFormattedDiscountedPrice}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}
